$(document).on("turbo:load", () => {

  var alertSound = document.getElementById("channel_alert_sound");

  if(alertSound){
    alertSound.onchange = function(){
      var url = "https://files.voupe.com/gamerclothing/" + alertSound.value
      var audio = new Audio(url);
      audio.volume = 0.4;
      audio.play();
    };
  }

})